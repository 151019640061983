/**
 * ExCo player assigns height, width to the parent element it renders into
 * (at least does so for Surfer's Player ID, not Dengarden's). This breaks
 * responsive design, so this unsets them.
 */
.exco-player-wrapper {
	clear: both;
	height: unset !important;
	width: unset !important;
}

/**
 * Assigns a top and bottom margin and disables left, right margins for
 * the non-floating player
 */
.exco-player-wrapper .pbs[data-pbs-position="static"] {
	--pbs-margin-top: 0 !important;
	--pbs-margin-bottom: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

/* Changes to make the player scale to container width */
.exco-player-wrapper .pbs[data-pbs-position="static"] .pbs__player {
	height: auto !important;
	width: 100% !important;
}

/**
 * Adjusts the location of the floating player to take footer ad
 * height into account (particularly when it is dismissed and the
 * footer height is 0).
 */
.exco-player-wrapper .pbs[data-pbs-position="sticky"][data-pbs-sticky="bottom-right"] .pbs__player {
	bottom: calc(var(--footer-ad-height) + var(--spacing-s)) !important;

	@media (--allows-motion) {
		transition: bottom var(--transition-speed-normal);
	}
}

.single-post .exco-player-wrapper .pbs[data-pbs-position="sticky"][data-pbs-sticky="upper-misty"] .pbs__player {
	top: calc(var(--sticky-height, --header-height)) !important;

	@media (--allows-motion) {
		transition: top var(--transition-speed-normal);
	}
}

/**
* Changes the focus outline of the player controls
*/
.exco-player-wrapper .exp-ui__wrapper {
	--outline-color: var(--color-theme-dark-link-focus-outline-color);
	--outline-style: var(--color-theme-dark-link-focus-outline-style);
	--outline-width: var(--color-theme-dark-link-focus-outline-width);
}
